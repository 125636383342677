app.controller('RestorePasswordCtrl', function ($scope, $rootScope,$location, $translate, AppFactory, Notification, LoaderFactory, ErrFactory, API) {
  $scope.email = '';
  $scope.userForm = null;
  $scope.loading = false;

  $scope.restoreEmail = () => {
    if (!$scope.email || $scope.email.trim() === 0) {
      return $translate(['error', 'enterEmailOrPNumber']).then((result) => {
        return Notification.alert({
          title: 'error',
          desc: 'enterEmailOrPNumber'
        });
      });
    }

    let rgx = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$|^\d+$/;
    if (!rgx.test($scope.email.trim())) {
      return $translate(['error', 'activationAccount.enterEmailOrPNumber']).then((result) => {
        return Notification.alert({
          title: 'error',
          desc: 'activationAccount.emailPersonnelNumberInvalid'
        });
      });
    }

    if (!AppFactory.isNetwork()) {
      return AppFactory.networkProblem();
    }

    $scope.loading = true;
    LoaderFactory.show();
    let params = {
      user: {
        email: $scope.email.trim()
      }
    };

    API.restorePassword(params)
      .then((ans) => {
        LoaderFactory.hide();
        Notification.alert({
          title: 'notice',
          desc: 'successRestorTokenSent'
        }, () => {
          $rootScope.resetEmail = $scope.email.trim();
          $location.path('/restore-password-by-token');
          $scope.loading = false;
          $scope.$apply();
        });
      }).catch((err) => {
        LoaderFactory.hide();
        if (err instanceof ErrFactory) err.notify(() => $scope.loading = false);
        else {
          console.error(err);
          $scope.loading = false;
        }
      });
  };

  $scope.restorePasswordWithToken = () => {
    if ($scope.password !== $scope.password_confirmation) return Notification.alert({ title: 'error', desc: 'errors.samePasswords' });
    if (!AppFactory.isNetwork())                          return AppFactory.networkProblem();

    $scope.loading = true;
    LoaderFactory.show();

    Promise.resolve(API.restorePasswordByToken({
      user: {
        email:                 $rootScope.resetEmail.trim(),
        password:              $scope.password.trim(),
        password_confirmation: $scope.password_confirmation.trim(),
        reset_password_token:  $scope.token.trim(),
      }
    }))
      .then(() => {
        LoaderFactory.hide();
        return Notification.alert({
          title: 'successfully',
          desc: 'successRestorePasswordByToken'
        }, () => {
          $location.url($location.path('/sign-in?email=' + $rootScope.resetEmail));
          $scope.$apply();
        });
      })
      .then(() => $rootScope.resetEmail = null)
      .catch((err) => {
        if (err instanceof ErrFactory) err.notify(() => $scope.loading = false);
        else {
          console.error(err);
          $scope.loading = false;
        }
      })
      .then(() => {
        LoaderFactory.hide();
      });
  };

});
