window.app.controller('listProjectsCtrl', [
          '$scope', '$rootScope', '$location', 'AppFactory', 'AuthService', 'LoaderFactory', 'AssignmentFactory', 'SyncService', '$window',
  function($scope,   $rootScope,   $location,   AppFactory,   AuthService,   LoaderFactory,   AssignmentFactory,   SyncService,   $window) {

  $scope.dateFormat = localStorage.getItem('date_format') + " " + "HH:mm";
  $scope.load = {
    init:   false,
    banner: false
  };

  $rootScope.paginationPage       = null;  
  $rootScope.paginationColumn     = null;
  $rootScope.paginationReverse    = null;
  $rootScope.paginationTotalCount = null;

  $scope.showProjectsHandler = function () {
    if ($scope.load.init && $scope.load.banner && $rootScope.ebs && $rootScope.ebs.loaded) LoaderFactory.hide();
    else LoaderFactory.show();
  };

  $scope.checkLoaded = function() {
    return $scope.load.init && $scope.load.banner;
  };

  $scope.applyCampaignImage = function (campaignImage) {
    if      (campaignImage.link)      $window.open(campaignImage.link, '_system');
    else if (campaignImage.deep_link) $window.location.href = campaignImage.deep_link;
  };

  $scope.$watch(   'load', (newVal, oldVal) => { if (newVal && newVal.init && newVal.banner) $scope.showProjectsHandler(); }, true);
  $rootScope.$watch('ebs', (newVal, oldVal) => { if (newVal && newVal.loaded               ) $scope.showProjectsHandler(); }, true);

  /**
   * Construct
   * @memberof ListProjectsCtrl
   * @private
   */
  var init = function() {
    if (window.cordova && window.navigator.splashscreen) window.navigator.splashscreen.hide();

    LoaderFactory.show();
    LoaderFactory.initShowCancelButtonText();

    return app.AM.promiseOfUserLoad
    .catch((err) => {
      console.error(err);
      if (err && err.store_url) {
        $location.path('/blocker');
        $scope.$apply();
      }
    })
    .then(() => AssignmentFactory.getOwn())
    .then(() => $scope.load.init = true)
    .then(() => $scope.showProjectsHandler());
  };

  $scope.checkFeatureFlag = function(flag) {
    return AppFactory.isNetwork() && AuthService.authorizedUser && AuthService.authorizedUser.features && AuthService.authorizedUser.features[flag];
  };

  $scope.changeLocation = function(event, to) {
    event.stopPropagation();
    $location.path(to);
  };

  /**
   * Handler click button sync
   * @memberof ListProjectsCtrl
   * @event ListProjectsCtrl#project.sync
   */
  var projectSyncHandler = $scope.$on('project.sync', function() {
    SyncService.userSync();
  });

  $scope.$on('$destroy', function() {
    projectSyncHandler();
  });

  return init();
}]);
